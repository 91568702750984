@import "node_modules/@blueprintjs/core/lib/css/blueprint.css";
@import "node_modules/@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
@import "node_modules/swiper/swiper.min.css";
@import "node_modules/swiper/modules/a11y/a11y.min.css";
@import "node_modules/swiper/modules/controller/controller.min.css";
@import "node_modules/swiper/modules/effect-fade/effect-fade.min.css"; // fade 이펙트 사용으로 추가
//@import "node_modules/swiper/modules/mousewheel/mousewheel.min.css";
@import "node_modules/swiper/modules/pagination/pagination.min.css";
//@import "node_modules/swiper/modules/scrollbar/scrollbar.min.css"; // 슬라이드시 스크롤바가 노출되므로 추가하지 않음

:root {
  font-size: 14px;
}

:focus {
  outline: 0;
}

html {
  display: block;
  margin: 0 auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
a,
button,
p,
ul,
ol,
dl {
  margin: 0;
  padding: 0;
  border: 0;
  word-break: keep-all;
}

button {
  background-color: transparent;
}
