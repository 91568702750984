.kidsnote-cal {
  width: 240px;

  .bp3-button-group {
    margin-top: 20px;
  }
}

.kidsnote-content {
  .subject {
  }

  .heading {
    overflow: hidden;

    .head-left,
    .head-right {
      &:after {
        content: "";
        clear: both;
      }
    }
    .head-left {
      float: left;
    }
    .head-right {
      float: right;
    }
  }

  .article {
    line-height: 1.5em;
    text-align: justify;
  }

  .writer,
  .comment-writer {
    text-align: right;
    font-size: 0.8em;
    opacity: 0.5;
  }

  .comment-writer {
    margin-top: 8px;
  }

  .photo {
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 5px 0;
  }
}

.photo-view {
  display: none;

  &.show {
    display: block;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.95);
  }

  .photo-view-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .btn-photo-view-close {
    position: fixed;
    z-index: 100;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
  }
}
